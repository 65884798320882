<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center v-if="access">
      <v-flex xs11 sm10 md10>
        <v-card class="elevation-12 fonts">
          <v-toolbar dark color="primary">
            <v-toolbar-title> گزارش عملکرد محصولات </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <h2>
              جستجوی محصول
            </h2>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="product"
                  :items="itens"
                  hide-no-data
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  dense
                  color="white"
                  :height="48"
                  placeholder="جستجو در محصولات ..."
                  no-filter
                  class="white--text"
                  @keyup="search()"
                  @input="showDetails()"
                >
                <template v-slot:selection="data">
                {{data.item.name}}
                </template>
                <template v-slot:item="data">
                  {{data.item.name}}
                </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-card v-if="ProductDetails && product" >
                  <v-card-title>
                    {{product.name}}
                  </v-card-title>
                  <v-card-text>
                    تعداد فروش : {{ ProductDetails.total_count | fanum }}
                  </v-card-text>
                  <v-card-text>
                    مبلغ فروش : {{ ProductDetails.total_sales | comma | fanum }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row v-if="products.by_quantity">
              <v-col cols="12">
                <h3>
                  برترین محصولات از لحاظ تعداد
                </h3>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" v-for="(product, index) in products.by_quantity.best" :key="index">
                <v-card >
                  <v-card-title>
                    {{product.product_name}}
                  </v-card-title>
                  <v-card-text>
                    تعداد فروش : {{ product.quantity | fanum }}
                  </v-card-text>
                  <v-card-text>
                    مبلغ فروش : {{ product.total_sale | comma | fanum }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row v-if="products.by_quantity">
              <v-col cols="12">
                <h3>
                  بدترین محصولات از لحاظ تعداد
                </h3>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" v-for="(product, index) in products.by_quantity.worst" :key="index">
                <v-card >
                  <v-card-title>
                    {{product.product_name}}
                  </v-card-title>
                  <v-card-text>
                    تعداد فروش : {{ product.quantity | fanum }}
                  </v-card-text>
                  <v-card-text>
                    مبلغ فروش : {{ product.total_sale | comma | fanum }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row v-if="products.by_sales">
              <v-col cols="12">
                <h3>
                  برترین محصولات از لحاظ مبلغ فروش
                </h3>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" v-for="(product, index) in products.by_sales.best" :key="index">
                <v-card >
                  <v-card-title>
                    {{product.product_name}}
                  </v-card-title>
                  <v-card-text>
                    تعداد فروش : {{ product.quantity | fanum }}
                  </v-card-text>
                  <v-card-text>
                    مبلغ فروش : {{ product.total_sale | comma | fanum }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row v-if="products.by_quantity">
              <v-col cols="12">
                <h3>
                  بدترین محصولات از لحاظ مبلغ فروش
                </h3>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" v-for="(product, index) in products.by_sales.worst" :key="index">
                <v-card >
                  <v-card-title>
                    {{product.product_name}}
                  </v-card-title>
                  <v-card-text>
                    تعداد فروش : {{ product.quantity | fanum }}
                  </v-card-text>
                  <v-card-text>
                    مبلغ فروش : {{ product.total_sale | comma | fanum }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import InvoceService from '../services/invoce'

export default {
  name: 'ProductReport',
  components: {
  },
  data: () => ({
    access: false,
    products: {},
    itens: [] ,
    product: {},
    ProductDetails: null,
    searchFlag: false
  }),
  computed: {
      loggedIn() {
          return this.$store.state.auth.fstatus.loggedIn;
        },
        rule() {
          return this.$store.state.auth.user.user_role;
        },
    },
    created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            this.access = true
            break;
          case 'SuperAdmin':
            this.access = true
            break;
          case 'FinancialManager':
            this.access = true
            break;
          case 'MonitoringOperator':
            this.access = true
            break;
          default:
            this.$router.push('/accessdenied');
        }
      }
    },
    mounted() {
      this.readData()
    },
    methods: {
      readData() {
        InvoceService.Performance().then(
            data => {
                if(data.status === 200) {
                  this.products = data.data
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      readDetails(id){
        InvoceService.ProductsPerformance(id).then(
            data => {
                if(data.status === 200) {
                  this.ProductDetails = data.data
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      search() {
        let x = event.target.value
        if(x){
          if (!this.searchFlag && x && x.length > 1) {
          this.searchFlag = true
          setTimeout(async () => {
            if (x && x.length > 1) {
              try {
                InvoceService.ListProducts(x).then(
              data => {
                  if(data.status === 200) {
                    if(data.data.products)
                      this.itens = data.data.products
                  }
              },
              error => {
                if (error.status === 401) {
                    this.$router.push('/accessdenied');
                  }
              })
              } catch (error) {
                if (error.status === 401) {
                    this.$router.push('/accessdenied');
                  }
              }
            }
            this.searchFlag = false
          }, 100)
          }
        }else {
          this.product = {}
        }
        
      },
      showDetails(){
        if (this.product)
          this.readDetails(this.product.id)
      }

    }

  }
</script>   