<template>
    <v-card class="fonts">
        <v-toolbar dark color="primary">
        <v-toolbar-title>افزودن سفارش</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="close()" color="white" text>بازگشت </v-btn>
        </v-toolbar>
        <v-card-text>
        <v-row>
            <v-col cols="12"><h3>اطلاعات مشتری</h3></v-col>
            <v-col :cols="isMobile ? 12 : 6">
                    <v-text-field v-model="customer.name"  label="نام مشتری" type="text">
            
                </v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6">
                <v-text-field v-model="customer.phone_number"  label="شماره موبایل">
            
                </v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 3">
                <v-text-field v-model="customer.city"  label="شهر ">
            
                </v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 9">
                <v-text-field v-model="customer.address"  label="آدرس ">
            
                </v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4">
                <v-text-field v-model="card_id"  label="چهار رقم آخر کارت " type="text">
            
                </v-text-field>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4">
                <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="payment_time"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="payment_time"
                        label="زمان پرداخت"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-time-picker
                    v-if="modal2"
                    v-model="payment_time"
                    format="24hr"
                    full-width
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="modal2 = false"
                    >
                        انصراف
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(payment_time)"
                    >
                        تایید
                    </v-btn>
                    </v-time-picker>
                </v-dialog>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="faMonthEnd"
                        label="تاریخ پرداخت"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="payment_date"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
            <!-- <v-col cols="6">
                <v-text-field v-model="customer.mobile"  label="">
            
                </v-text-field>
            </v-col> -->
            <v-col cols="12">
                  <h3 class="mt-5">محصولات</h3>
                  <v-autocomplete
                            v-model="products[pointer].product"
                            :items="itens"
                            hide-no-data
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            dense
                            color="white"
                            :height="48"
                            placeholder="جستجو در محصولات ..."
                            no-filter
                            class="white--text"
                            @keyup="search()"
                            @input="setPrice(pointer)"
                          >
                          <template v-slot:selection="data">
                          {{data.item.name}}
                          </template>
                          <template v-slot:item="data">
                            {{data.item.name}}
                          </template>
                          </v-autocomplete>
                </v-col>
            <v-col cols="12">
                <v-row v-for="(item, index) in products" :key="index">
                    <!-- <v-col :cols="isMobile ? 12 : 4">
                        <v-combobox
                            v-model="item.product"
                            :items="itens"
                            item-text="name"
                            item-value="id"
                            label="نام محصول"
                            class="fonts"
                            @input="setPrice(index)"
                        ></v-combobox>
                    </v-col> -->
                    <v-col :cols="isMobile ? 12 : 4">
                        <v-text-field v-if="item.product" readonly v-model="item.product.name" label="نام محصول" >
                        </v-text-field>
                        <v-text-field v-else readonly v-model="item.product" label="نام محصول" >
                        </v-text-field>
                    </v-col>
                    <v-col :cols="isMobile ? 6 : 2">
                        <v-text-field  :value="item.price| comma" @input="value => item.price = fanToEnStr(value)" label="قیمت" >
                        </v-text-field>
                    </v-col>
                    <v-col :cols="isMobile ? 6 : 2">
                        <v-text-field :value="item.paid_price| comma" @input="value => item.paid_price = fanToEnStr(value)"  label=" قیمت بعد از تخفیف" >
                        </v-text-field>
                        <!-- :value="item.paid_price| comma" @input="value => item.paid_price = value" -->
                    </v-col>
                    <v-col :cols="isMobile ? 6 : 2">
                        <v-text-field v-model="item.quantity" label=" تعداد" type="number">
                        </v-text-field>
                    </v-col>
                    <v-col :cols="isMobile ? 6 : 2" class="ma-auto">
                        <v-btn icon @click="addItem(index + 1)">
                            <v-icon color="green">mdi-plus-circle</v-icon>
                        </v-btn>
                        <v-btn icon @click="delItem(index)">
                            <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="products[0].price">
                    <v-col :cols="isMobile ? 12 : 4">
                    <span class="font-weight-black black--text"># جمع کل:</span>
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 2">
                    <span class="font-weight-black black--text"><span v-if="isMobile">قیمت کل:</span>{{ priceSum | comma }}</span>
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 2">
                    <span class="font-weight-black black--text"><span v-if="isMobile">قیمت کل با تخفیف: </span>{{ pricePaidSum | comma}}</span>
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 2">
                    <span class="font-weight-black black--text"><span v-if="isMobile">تعداد محصول: </span>{{ quantityNumber }}</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
                <v-textarea
                    outlined
                    v-model="description"
                    label="توضیحات:"
                ></v-textarea>
            </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addInvoice()">ارسال</v-btn>
        <v-btn @click="close()" color="primary" text>انصراف </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
// import product from '../../data/output'
import InvioceService from '../../services/invoce'
export default {
    props: {
      dataInvoice : {
        type: Object,
        required: true
      },
    //   description : {
    //     type: String,
    //     required: true
    //   },
    //   payment_time : {
    //     type: String,
    //     required: true
    //   },
    //   card_id : {
    //     type: String,
    //     required: true
    //   },
    },
    data: () => ({
    pointer: 0,
    searchValue: '',
    searchFlag: false,
    menu: false,
    sumResult: 0,
    messages: '',
    active: false,
    color: 'primary',
    payment_time: null,
    payment_date: null,
    card_id: null,
    description: "" ,
    modal2: false,
    customer: {
        name: '',
        phone_number: '',
        address:'',
    },
    products: [
        {
        product:'',
        price: '',
        paid_price: '',
        quantity: ''
        }
    ],
    itens: [],
    select: ''
    }),
    computed: {
        faMonthEnd() {
            if(this.payment_date)
            return this.$options.filters.famonth(this.payment_date);
            else
            return '--/--/--'
        },
        loggedIn() {
            return this.$store.state.auth.fstatus.loggedIn;
        },
        rule() {
        return this.$store.state.auth.user.user_role;
        },
        priceSum() {
        // return this.products.reduce((accumulator, object) => {
        //   return Math.floor(accumulator) + Math.floor(object.price);
        // }, 0);
        let sum = 0
        this.products.forEach(element => {
            let number = element.price.toString().split(',').join('');
            if(element.quantity !== '')
            sum += Number(element.quantity) * (Number(number));
            else
            sum += Number(number)
        });
        return sum
        },
        pricePaidSum() {
        let sum = 0
        this.products.forEach(element => {
            let number = element.paid_price.toString().split(',').join('');
            let number2 = element.price.toString().split(',').join('');
            if(element.quantity !== '')
            if(element.paid_price !== '')
                sum += Number(element.quantity) * (Number(number));
            else 
                sum += Number(element.quantity) * (Number(number2));
            else
            if(element.paid_price !== '')
                sum += Number(number)
            else
                sum += Number(number2)
        });
        return sum
        },
        quantityNumber() {
        let sum = 0
        this.products.forEach(element => {
            if(element.quantity !== '')
            sum += Number(element.quantity) 
            else
            sum += 1
        });
        return sum
        }
    },
    watch: {
        dataInvoice: {
            // the callback will be called immediately after the start of the observation
            immediate: true, 
            deep : true,
            handler (val) {
                this.pointer = val.products.length - 1 
                this.customer = val.customer
                this.description = val.description
                this.card_id = val.card_id
                this.payment_time = val.payment_date.substring(11, 16)
                this.products = val.products
                this.payment_date = val.payment_date.substring(0, 10)
                this.products = this.products.map(function(obj) {
                    obj['id'] = obj['product_id'] // Assign new key
                    delete obj['product_id'] // Delete old key
                    obj['name'] = obj['product_name']; // Assign new key
                    delete obj['product_name']
                    obj['product'] = obj
                    return obj;
                });
            }
        }
    },    
    methods: {
    close() {
        this.$emit('close')
    },
    addItem(index){
        const item = {
        product:'',
        price: '',
        paid_price: '',
        quantity: ''
        }
        this.products.splice(index, 0, item);
    },
    delItem(index) {
        if (index > 0) {
        this.products.splice(index, 1);
        }
    },
    setPrice(index) {
        // console.log(this.products[index].product.id)
        this.products[index].price = this.products[index].product.price
        // this.product[index].product = this.products[index].product.id
    },
    fanToEnStr(input) {
        if (input === undefined || input == null) return ''
        let str1 = input.toString().trim()
        if (str1 === '') return ''
        str1 = str1.replace(/۰/gim, '0')
        str1 = str1.replace(/۱/gim, '1')
        str1 = str1.replace(/۲/gim, '2')
        str1 = str1.replace(/۳/gim, '3')
        str1 = str1.replace(/۴/gim, '4')
        str1 = str1.replace(/۵/gim, '5')
        str1 = str1.replace(/۶/gim, '6')
        str1 = str1.replace(/۷/gim, '7')
        str1 = str1.replace(/۸/gim, '8')
        str1 = str1.replace(/۹/gim, '9')
        return str1
    },
    search() {
      let x = event.target.value
      if(x){
        if (!this.searchFlag && x && x.length > 1) {
        this.searchFlag = true
        setTimeout(async () => {
          if (x && x.length > 1) {
            try {
              InvioceService.ListProducts(x).then(
            data => {
                if(data.status === 200) {
                  if(data.data.products)
                    this.itens = data.data.products
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
            } catch (error) {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            }
          }
          this.searchFlag = false
        }, 100)
        }
      }else {
        this.products[this.pointer].product = {
        product:{name:''},
        price: '',
        paid_price: '',
        quantity: ''
      }
      }
      
    },
    addInvoice(){
        if(this.payment_time && this.card_id && this.customer.name && this.customer.phone_number && this.customer.address){
        this.card_id = this.fanToEnStr(this.card_id)
        this.customer.phone_number = this.fanToEnStr(this.customer.phone_number)
        let date = this.payment_date + 'T' + this.payment_time
        for(let x=0; x<this.products.length;x++){
            let y = this.products[x].product.id
            this.products[x].product = y
            if(this.products[x].paid_price === '')
            this.products[x].paid_price = this.products[x].price
            if(this.products[x].quantity === '')
            this.products[x].quantity = 1
            this.products[x].quantity = this.fanToEnStr(this.products[x].quantity)
            this.products[x].paid_price = this.fanToEnStr(this.products[x].paid_price)
            this.products[x].price = this.fanToEnStr(this.products[x].price)
            this.products[x].paid_price = Number(this.products[x].paid_price.toString().split(',').join(''))
            this.products[x].price = Number(this.products[x].price.toString().split(',').join(''))
        }
        this.customer.phone_number = '+98' +  this.customer.phone_number.slice(1, this.customer.phone_number.length)
        InvioceService.Add(this.card_id, date, this.customer, this.products, this.description).then(
            data => {
                if(data.status === 201) {
                    this.color = 'primary'
                    this.active = true
                    this.messages = "سفارش با موفقیت ساخته شد"
                    this.card_id = ""
                    this.payment_time = ""
                    this.products = [
                    {
                        product:'',
                        price: '',
                        paid_price: '',
                        quantity: ''
                    }
                    ],
                    this.pointer = 0
                    this.payment_date = null
                    this.customer = {
                        name:'',
                        phone_number: '',
                        password:'',
                        role: ''
                    }
                    this.close()
                } else if (data.status === 401) {
                    this.$router.push('/accessdenied');
                }
                else if (data.status === 400) {
                    this.color = 'error'
                    this.active = true
                    this.messages = "  فیلد ها را درست وارد نمایید."
                }
            },
            error => {
                this.customer.phone_number = '0' +  this.customer.phone_number.slice(3, this.customer.phone_number.length)
                this.products = [
                    {
                        product:'',
                        price: '',
                        paid_price: '',
                        quantity: ''
                    }
                    ],
                this.pointer = 0
                this.payment_date = null
                this.color = 'error'
                this.active = true
                if(error.response.data.card_id){
                this.messages = "شماره کارت را درست وارد نمایید"
                } else if (error.response.data.customer){
                if(error.response.data.customer.phone_number){
                    this.messages = "شماره تلفن را درست وارد نمایید"
                } else if (error.response.data.customer.name){
                    this.messages = "نام خانوادگی را درست وارد نمایید"
                } else if (error.response.data.customer.address){
                    this.messages = "آدرس  را درست وارد نمایید"
                }
                }else if (error.response.data.products){
                this.messages = "محصولات  را درست وارد نمایید"
                }else {
                this.messages = error.messages
                }
            })
        } else {
        this.color = 'error'
        this.active = true
        this.messages = "  فیلد ها را کامل وارد نمایید."
        }
    },

    },
    
};
</script>
<style>
.v-application {
    font-family: Vazirmatn !important;
}
</style>
<style scoped>
.f16{
    font-size: 18px;
}
</style>