export default [
    "SuperAdmin"
    ,
    "OWNER"
    ,
    "FinancialManager"
    ,
    "SocialOperator"
    ,
    "PackingOperator"
    ,
    "SendingOperator"
    ,
    "DeliveryOperator"
    ,
    "AccountingOperator"
    ,
    "MonitoringOperator"
];