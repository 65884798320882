<template>
  <v-flex xs11 sm10 md10 class="fonts">
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="جست وجو"
          single-line
          hide-details
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
        no-data-text="موردی برای نمایش وجود ندارد."
        :loading="loadingTable"
        :headers="dessertHeaders"
        :items="invioces"
        single-expand
        disable-pagination
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        disable-sort
        hide-default-footer
        class="elevation-1"
        :search="search"
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title> {{title}} </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        </template>
        <template #item.index="{ item }">
          {{ invioces.indexOf(item) + 1 | numEnToFa  }}
        </template>
        <template v-slot:item.shygun_id="{ item }">
            <!-- {{item.id}} -->
            {{ item.shygun_id | numEnToFa}}
        </template>
        <template v-slot:item.payment_date="{ item }">
            <!-- {{item.id}} -->
            {{ item.payment_date | famonthDay}}
        </template>
        <template v-slot:item.paid_price="{ item }">
            <!-- {{item.id}} -->
            {{ item.paid_price | comma}}
        </template>

        <template v-slot:item.state="{ item }">
            <!-- {{item.id}} -->
            <v-chip label outlined color="primary">{{stateShow(item.state)}}</v-chip>
        </template>
        <template v-slot:item.created_at="{ item }">
            <!-- {{item.id}} -->
            {{ item.created_at | famonthDay}}
        </template>
        <template v-slot:item.user_id="{ item }">
            <!-- {{item.id}} -->
            <v-btn block v-if="sendState !== -1" class="ma-2" color="primary" @click="checkAcceptAction(item.id)"> {{btnText1}}   </v-btn>
            <v-btn block v-else class="ma-2" color="primary" @click="editeInvoice(item)"> {{btnText1}}   </v-btn>
            <v-btn block v-if="btnText2" class="ma-2" color="error" @click="checkRejecAction(item.id)"> {{btnText2}} </v-btn>
            <print-invoice v-if="printed" :address="item.customer.address" :mobile="item.customer.phone_number" :receiver="item.customer.name"></print-invoice>
        </template>
        <template v-slot:item.data-table-expand="{item, isExpanded}">
          <v-btn
            outlined
            color="warning"
            @click="handleExpansion(item, isExpanded)"
          >{{ isExpanded ? 'بستن' : 'مشاهده اطلاعات بیشتر' }}</v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
            <!-- {{ item.products }} -->
            <v-row class="my-2">
              <v-col cols="12">
                <v-data-table
                    disable-sort
                    :headers="ItemHeaders"
                    :items="item.products"
                    item-key="product_id"
                    hide-default-footer
                    class="elevation-1"
                    :items-per-page="-1"
                >
                  <template v-slot:item.product_id="{ item }">
                    {{ item.product_name  }}
                    
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
              <v-text-field
                  class=""
                  outlined
                  readonly
                  :value="item.customer.city"
                  label="شهر "
              ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="!isMobile">
                <v-text-field
                    outlined
                    class="mt-n8"
                    readonly
                    v-model="item.customer.address"
                    label="آدرس"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="isMobile">
                <v-textarea
                    class="mt-n8"
                    outlined
                    readonly
                    v-model="item.customer.address"
                    label="آدرس"
                ></v-textarea>
              </v-col>
              <v-col cols="12" >
              <v-text-field
                  class="mt-n8"
                  outlined
                  readonly
                  :value="item.customer.phone_number | MobileFilter"
                  label="شماره تلفن"
              ></v-text-field>
              </v-col>
              <v-col v-for="(x,index) in Object.keys(item.description_json)" :key="index" cols="12" class="mt-n8">
                <div v-if="item.description_json[x] !== ''">
                  <div v-if="x != readState">
                    <h4> {{descriptionState(x)}}</h4>
                    <v-textarea
                        outlined
                        readonly
                        v-model="item.description_json[x]"
                        label="توضیحات:"
                    ></v-textarea>
                  </div>
                </div>
                <div v-if="x == readState">
                  <h4> {{descriptionState(x)}}</h4>
                  <v-textarea
                      outlined
                      
                      v-model="item.description_json[x]"
                      label="توضیحات:"
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
        </td>
        </template>
        <template v-slot:footer >
            <v-row  class="justify-center " >
                <v-btn color="primary" :disabled="invioces.length < 98" @click="readDateWithOfSet()" class="ma-5">
                    موارد بیشتر
                </v-btn>
            </v-row>
        </template>
    </v-data-table>
    <v-snackbar
      v-model="active"
      multi-line
      :color="color"
      class="fonts"
      bottom
      right
    >
      {{ messages }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="active = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      class="fonts"
      v-model="modal"
      width="50vw"
    >
      <v-card>
        <v-card-title class=" fonts">
         تایید عملیات
        </v-card-title>
        <v-card-text class="fonts"> {{ modalMessage }} ؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="fonts"
            color="black"
            text
            @click="cancelModal"
          >
            بستن
          </v-btn>
          <v-btn
            class="fonts"
            :color="modalColor"
            @click="verifyModal"
          >
            تایید
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="editDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
       <add-invoice v-if="editeItem" v-on:close="editDialog = false" :dataInvoice="editeItem" ></add-invoice>
      </v-dialog>
  </v-flex>
</template>

<script>
import InvioceService from '../../services/invoce'
import product from '../../data/output'
import printInvoiceVue from './printInvoice.vue'
import printInvoice from './printInvoice.vue'
import AddInvoice from './addInvoice.vue'

  export default {
  components: {
    printInvoice,
    AddInvoice
},
    comments: {
      printInvoiceVue,
    },
    props: {
      title: {
        type: String,
        required: true
      },
      btnText1: {
        type: String,
        required: true
      },
      btnText2: {
        type: String,
        required: false
      },
      readState: {
        type: Number,
        required: true
      },
      sendState: {
        type: Number,
        required: true
      },
      rejectState: {
        type: Number,
        required: false
      },
      printed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data () {
      return {
        modal: false,
        modalMessage: '',
        modalColor: 'primary',
        acceptID: 0,
        rejectID: 0,
        search:'',
        offset: 0,
        loadingTable: true,
        products: product,
        messages: '',
        active: false,
        color: 'primary',
        invioces: [],
        expanded: [],
        singleExpand: false,
        ItemHeaders: [
          { 
            text: 'محصول ', value: 'product_id' , align: 'start', sortable: false,
            // filter: value => {
            //  return (this.products.find(x => x.id === this.value)).name + value
            // }
          },
          { text: 'قیمت', value: 'price' },
          { text: ' مبلغ پرداخت شده', value: 'paid_price' },
          { text: ' تعداد', value: 'quantity' },
        ],
        dessertHeaders: [
          {
            value: 'index',
            text: '#',
          },
          {
            value: 'id',
            text: 'شماره سفارش',
          },
          {
            value: 'shygun_id',
            text: 'شماره شایگان',
          },
          { text: 'نام مشتری', value: 'customer.name' , align: 'start', sortable: false,},
          { text: 'چهار رقم آخر کارت', value: 'card_id' },
          { text: 'ساعت پرداخت', value: 'payment_date' },
          { text: 'مبلغ پرداخت شده', value: 'paid_price' },
          { text: ' تاریخ ایجاد', value: 'created_at' },
          { text: 'وضعیت', value: 'state' },
          { text: 'عملیات', value: 'user_id' , align: 'center'},
          { text: '', value: 'data-table-expand' },
        ],
        editDialog: false,
        editeItem: null
      }
    },
    mounted() {
      setInterval(() => this.readData(), 120000);
    },
    created() {
        this.readData()
    },
    methods: {
      readData() {
        this.loadingTable = true
        InvioceService.Get(this.readState).then(
            data => {
                if(data.status === 200) {
                  this.invioces = data.data.invoices
                  this.loadingTable = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      accept(id){
        InvioceService.Update(id,this.sendState,this.invioces.find(x => x.id === id).description_json[this.readState]).then(
            data => {
                if(data.status === 200) {
                  this.active = true
                  this.messages = "با موفقیت ثبت شد"
                  this.readData()
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      reject(id){
        InvioceService.Update(id,this.rejectState).then(
            data => {
                if(data.status === 200) {
                  this.active = true
                  this.messages = "با موفقیت ثبت شد"
                  this.readData()
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      stateShow(state){
        let stateMessage
        switch (state) {
          case 1:
            stateMessage = "در انتظار تایید مالی"
            break;
          case 2:
            stateMessage = " رد شده مالی"
            break;
          case 3:
            stateMessage = "در انتظار بسته بندی"
            break;
          case 4:
            stateMessage = "در انتظار ارسال"
            break;
          case 5:
            stateMessage = " ارسال شده"
            break;
          case 6:
            stateMessage = "تحویل مشتری"
            break;
          case 7:
            stateMessage = "نهایی"
            break;
          case 8:
            stateMessage = "رد نهایی"
            this.stateColor = "deep-green"
            break;
          default:
            stateMessage = "تعریف نشده"
        }
        return stateMessage
      },
      handleExpansion(item, state) {
        const itemIndex = this.expanded.indexOf(item);

        state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
      },
      readDateWithOfSet(){
        this.offset = this.offset + 100
        this.loadingTable = true
        InvioceService.GetOffset(this.readState,this.offset).then(
            data => {
                if(data.status === 200) {
                  this.invioces = this.invioces.concat(data.data.invoices)
                  this.invioces = this.invioces.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.id === value.id
                    ))
                  )
                  this.loadingTable = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      checkAcceptAction(id){
        this.acceptID = id
        this.modalMessage = 'آیا از تایید شماره سفارش' + id + 'مطمئن هستید'
        this.modalColor = 'success'
        this.modal = true
      },
      checkRejecAction(id){
        this.rejectID = id
        this.modalMessage = 'آیا از رد شماره سفارش  ' + id + '  مطمئن هستید'
        this.modalColor = 'error'
        this.modal = true
      },
      verifyModal(){
        if(this.modalColor === 'error'){
          this.reject(this.rejectID)
          this.modal = false
        } else {
          this.accept(this.acceptID)
          this.modal = false
        }
      },
      cancelModal(){
        this.modal = false
        this.acceptID = 0
        this.rejectID = 0
        this.modalColor = 'primary'
      },
      editeInvoice(item){
        this.editDialog = true
        this.editeItem = item
        this.editeItem.customer.phone_number = this.editeItem.customer.phone_number = '0' +  this.editeItem.customer.phone_number.slice(3, this.editeItem.customer.phone_number.length)
        this.readData()
      },
      descriptionState(id){
        let stateMessage
        switch (id) {
          case '0':
            stateMessage = "ادمین شبکه اجتماعی"
            break;
          case '1':
            stateMessage = "مدیر مالی"
            break;
          case '2':
            stateMessage = " مسئول بسته بندی  "
            break;
          case '3':
            stateMessage = " مسئول ارسال"
            break;
          case '4':
            stateMessage = "مسئول تایید"
            break;
          case '5':
            stateMessage = "مدیر"
            break;
          case '6':
            stateMessage = "تایید نهایی"
            break;
          default:
            stateMessage = "تعریف نشده"
        }
        return stateMessage
      },
    }

  }
</script>   

<style>

</style>