<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center v-if="access">
      <v-flex xs11 sm10 md10>
        <v-card class="elevation-12 fonts">
          <v-toolbar dark color="primary">
            <v-toolbar-title> گزارش عملکرد کاربران </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(user,i) in Users"
                :key="i"
                @click="readDetails(user.id)"
              >
                <v-expansion-panel-header>
                  کاربر: {{user.username}}
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="mt-6">
                  <v-row v-if="UserDetails" class="text-center">
                    <v-col :cols="isMobile ? 12 : 3">
                      <h3 class="mb-3"> تعداد سفارش </h3>
                      {{UserDetails.orders_count | fanum}}
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 3">
                      <h3 class="mb-3"> تعداد مشتری </h3>
                      {{UserDetails.customers_count | fanum}}
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 3">
                      <h3 class="mb-3"> مقدار فروش </h3>
                      {{UserDetails.sales_income | comma |fanum}}
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 3">
                      <h3 class="mb-3"> تعداد روزهای فعال </h3>
                      {{UserDetails.active_days | fanum}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h2>
                      محصولات فروخته شده
                      </h2>
                    </v-col>
                    <v-col cols="12" v-if="UserDetails">
                      <v-data-table
                          disable-sort
                          :headers="ItemHeaders"
                          :items="UserDetails.products"
                          item-key="name"
                          hide-default-footer
                          class="elevation-1"
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <!-- {{UserDetails}} -->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import UserService from '../services/user'

export default {
  name: 'OpratorReport',
  components: {
  },
  data: () => ({
    access: false,
    Users: [],
    UserDetails: null,
    ItemHeaders: [
          { 
            text: 'محصول ', value: 'name' , align: 'start', sortable: false,
            // filter: value => {
            //  return (this.products.find(x => x.id === this.value)).name + value
            // }
          },
          { text: ' مبلغ فروخته شده', value: 'total_sales' },
          { text: ' تعداد فروش', value: 'quantity' },
        ],
  }),
  computed: {
      loggedIn() {
          return this.$store.state.auth.fstatus.loggedIn;
        },
        rule() {
          return this.$store.state.auth.user.user_role;
        },
    },
    created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            this.access = true
            break;
          case 'SuperAdmin':
            this.access = true
            break;
          case 'FinancialManager':
            this.access = true
            break;
          case 'MonitoringOperator':
            this.access = true
            break;
          default:
            this.$router.push('/accessdenied');
        }
      }
    },
    mounted() {
      this.readData()
    },
    methods: {
      readData() {
        UserService.List().then(
            data => {
                if(data.status === 200) {
                  this.Users = data.data.users
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      readDetails(id){
        UserService.Performance(id).then(
            data => {
                if(data.status === 200) {
                  this.UserDetails = data.data
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      }
    }

  }
</script>   