<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center v-if="access">
      <checkPage :btnText1="dataInput.text1" :title="dataInput.title" :readState="dataInput.readstate" :sendState="dataInput.sendstate" :btnText2="dataInput.text2" :rejectState="dataInput.rejectState"></checkPage>
    </v-layout>
  </v-container>
</template>
<script>
import checkPage from '../components/Page/checkPage.vue'

export default {
  name: 'AccountingCheck',
  components: {
    checkPage,
  },
  data: () => ({
    access: false,
    dataInput: {
      title: 'بررسی نهایی',
      text1: 'تایید نهایی',
      text2: 'رد نهایی',
      readstate: 6,
      sendstate: 7,
      rejectstate: 8,
    }
  }),
  computed: {
      loggedIn() {
          return this.$store.state.auth.fstatus.loggedIn;
        },
        rule() {
          return this.$store.state.auth.user.user_role;
        },
    },
    created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            this.access = true
            break;
          case 'SuperAdmin':
            this.access = true
            break;
          case 'FinancialManager':
            this.access = true
            break;
          case 'AccountingOperator':
            this.access = true
            break;
          default:
            this.$router.push('/accessdenied');
        }
      }
    },
  methods:{
  },
  watch: {
    },
};
</script>
