<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs11 sm10 md10>
        <v-card class="elevation-12 fonts">
          <v-toolbar dark color="primary">
            <v-toolbar-title> ساخت کاربر</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="success" to="/userlist"> بازگشت</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
                <v-col cols="12"><h3>اطلاعات کاربر</h3></v-col>
                <v-col :cols="isMobile ? 12 : 6">
                     <v-text-field v-model="customer.name"  label="نام کاربری" type="text">
               
                    </v-text-field>
                </v-col>
                <v-col :cols="isMobile ? 12 : 6">
                    <v-text-field v-model="customer.phone_number" placeholder="09123547699" label="شماره موبایل">
               
                    </v-text-field>
                </v-col>
                <v-col  :cols="isMobile ? 12 : 6">
                    <v-text-field v-model="customer.password" type="password"  label="پسورد ">
               
                    </v-text-field>
                </v-col>
                <v-col :cols="isMobile ? 12 : 6">
                    <v-select
                      :items="list"
                      filled
                      v-model="customer.role"
                      label="نقش"
                    ></v-select>
                </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="adduser()">ارسال</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="active"
      multi-line
      :color="color"
      class="fonts"
      bottom
      right
    >
      {{ messages }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="active = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import UserService from '../services/user'
import role from '../data/roleList'
export default {
  data: () => ({
    messages: '',
    active: false,
    color: 'primary',
    customer: {
        name:'',
        phone_number: '',
        password:'',
        role: ''
    },
    OwnerList: role.Owner,
    SuperAdminList: role.SuperAdmin,
    FinancialManagerList: role.FinancialManager
  }),
  created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            break;
          case 'SuperAdmin':
            break;
          case 'FinancialManager':
            break;
          default:
            this.$router.push('/accessdenied');
        }
      }
    },
  computed: {
    loggedIn() {
        return this.$store.state.auth.fstatus.loggedIn;
    },
    rule() {
        return this.$store.state.auth.user.user_role;
    },
    list() {
        if (this.$store.state.auth.fstatus.loggedIn) {
        switch (this.$store.state.auth.user.user_role) {
          case 'OWNER':
            return this.OwnerList
          case 'SuperAdmin':
            return this.SuperAdminList
          case 'FinancialManager':
            return this.FinancialManagerList
        }
      }
      return 0
    }
  },
  methods: {
    adduser() {
      if(this.customer.name && this.customer.phone_number && this.customer.password && this.customer.role){
        this.customer.phone_number = '+98' +  this.customer.phone_number.slice(1, this.customer.phone_number.length)
        UserService.Add(this.customer).then(
          data => {
              if(data.status === 201) {
                                                                                                                                                                              this.active = true
                this.messages = "کاربر با موفقیت ساخته شد"
                this.customer = {
                    name:'',
                    phone_number: '',
                    password:'',
                    role: ''
                }
              } else if (data.status === 401) {
                this.$router.push('/accessdenied');
              }
              else if (data.status === 400) {
                this.active = true
                this.messages = "  فیلد شماره تلفن را درست وارد نمایید."
              }
          },
          error => {
            this.customer.phone_number = '0' +  this.customer.phone_number.slice(3, this.customer.phone_number.length)
            this.active = true
            if(error.response.data.username) {
              this.messages = "نام کاربری تکراری می باشد"
            } else if (error.response.data.mobile_number){
              this.messages = "شماره تلفن را درست وارد نمایید"
            }
            
          })
      } else {
        this.active = true
        this.messages = "لطفا اطلاعات را کامل وارد نمایید."
      } 
    }
  },
};
</script>
<style >
.v-application {
  font-family: Vazirmatn !important;
}
</style>