import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_BASE_URL   // process.env.VUE_APP_BASE_URL + '/api' //'http://138.201.77.42:8081/api'

class InvoceService {
//   getList() {
//     return axios.get(API_URL + 'backup/',);
//   }
//   deleteItem(id) {
//     return axios.delete(API_URL + 'backup/'+ id + "/",);
//   }
  Add(D1, D2, D3, D4, D5) {
    return axios.post(API_URL + 'sales/invoice',{card_id:D1, payment_date: D2, customer : D3, products: D4,description: D5 },{ headers: authHeader() });
  }
  Get(state){
    return axios.get(API_URL + 'sales/invoice?state='+ state + '&limit=100&offset=0',{ headers: authHeader() });
  }
  GetOffset(state,offset){
    return axios.get(API_URL + 'sales/invoice?state='+ state + '&limit=100&offset=' + offset,{ headers: authHeader() });
  }
  Update(id,states,description) {
    return axios.put(API_URL + 'sales/invoice?id=' + id, {state: states, description: description} , { headers: authHeader() })
  }
  ListInvoice(state, from, to, data,offset) {
    return axios.get(API_URL + 'sales/invoice?state='+ state +'&from=' + from + '&to=' + to + data +'&limit=100&offset=' + offset,{ headers: authHeader() });
  }
  InvalidInvoice(id) {
    return axios.put(API_URL + 'sales/invoice_validity?id='+ id,{valid: 0},{ headers: authHeader() });
  }
  DetailsInvoice(id) {
    return axios.get(API_URL + 'sales/invoice_detail?id=' + id,{ headers: authHeader() });
  }
  Performance(){
    return axios.get(API_URL + 'sales/product_stat',{ headers: authHeader() });
  }
  ListProducts(name){
    return axios.get(API_URL + 'sales/product?name=' + name + '&limit=20&offset=0',{ headers: authHeader() });
  }
  UpdateProducts(){
    return axios.put(API_URL + 'sales/product',{},{ headers: authHeader() });
  }
  ProductsPerformance(id){
    return axios.get(API_URL + 'sales/product_sales_detail?id=' + id,{ headers: authHeader() });
  }
}
export default new InvoceService();