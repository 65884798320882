<template>
  <v-flex xs11 sm10 md10 class="fonts">
  <v-card>
    <v-card-title>جست و جو سفارش ها</v-card-title>
    <v-card-text>
      <v-row>
        <v-col :cols="isMobile ? 12 : 6">
          <v-text-field
            label="عبارت مورد نظر را وارد"
            outlined
            v-model="InputData"
          > 
          </v-text-field>
        </v-col>
        
        <v-col :cols="isMobile ? 12 : 6">
          <v-radio-group
            v-model="radioGroup"
            row
          >
            <v-radio
              label="نام کاربری"
              value="1"
            ></v-radio>
            <v-radio
              label="شماره سفارش"
              value="2"
            ></v-radio>
            <v-radio
              label="شماره فاکتور در شایگان"
              value="3"
            ></v-radio>
            <v-radio
              label="شماره تلفن مشتری"
              value="4"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col :cols="isMobile ? 12 : 4">
          <v-menu
            class="fonts"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="fonts"
                v-model="faMonthStart"
                label="تاریخ شروع"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateStart"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col :cols="isMobile ? 12 : 4">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="faMonthEnd"
                label="تاریخ پایان"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateEnd"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col :cols="isMobile ? 12 : 3">
        <v-select
            class="fonts"
            v-model="state"
            :items="invoicsState"
            label="وضعیت سفارش"
            outlined
            item-value="key"
            item-text="text"
          > 
          </v-select>
          
        </v-col>
        <v-col :cols="isMobile ? 0 : 8">
          
        </v-col>
        <v-col :cols="isMobile ? 12 : 3">
          <v-btn color="primary" @click="searchInvoice()" height="50" block>
            جست و جو
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-divider></v-divider>    
    <v-data-table
        no-data-text="موردی برای نمایش وجود ندارد."
        :loading="loadingTable"
        :headers="dessertHeaders"
        :items="invioces"
        single-expand
        disable-pagination
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        disable-sort
        hide-default-footer
        class="elevation-1"
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title> {{title}} </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        </template>
        <template #item.index="{ item }">
          {{ invioces.indexOf(item) + 1 | numEnToFa  }}
        </template>
        <template v-slot:item.shygun_id="{ item }">
            <!-- {{item.id}} -->
            {{ item.shygun_id | numEnToFa}}
        </template>
        <template v-slot:item.payment_date="{ item }">
            <!-- {{item.id}} -->
            {{ item.payment_date | famonthDay}}
        </template>
        <template v-slot:item.paid_price="{ item }">
            <!-- {{item.id}} -->
            {{ item.paid_price | comma}}
        </template>
        <template v-slot:item.state="{ item }">
            <!-- {{item.id}} -->
            <v-chip label outlined :color="showColor(item.state)">{{stateShow(item.state)}}</v-chip>
        </template>
        <template v-slot:item.created_at="{ item }">
            <!-- {{item.id}} -->
            {{ item.created_at | famonthDay}}
        </template>
        <template v-slot:item.user_id="{ item }">
            <v-btn v-if="rule === 'SuperAdmin'" block class="ma-2" color="error" @click="deleteInvoice(item.id)"> حذف سفارش   </v-btn>
            <v-btn v-if="rule === 'SuperAdmin'" block class="ma-2" color="primary" @click="changeState(item)"> تغییر وضعیت </v-btn>
            <v-btn v-if="rule !== 'SocialOperator'" block class="ma-2" color="success" :to="'/orderstate/' + item.id" >  مشاهده گردش کار </v-btn>
        </template>
        <template v-slot:item.data-table-expand="{item, isExpanded}">
          <v-btn
            outlined
            color="warning"
            @click="handleExpansion(item, isExpanded)"
          >{{ isExpanded ? 'بستن' : 'مشاهده اطلاعات بیشتر' }}</v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
            <!-- {{ item.products }} -->
            <v-row class="my-2">
              <v-col cols="12">
                <v-data-table
                    disable-sort
                    :headers="ItemHeaders"
                    :items="item.products"
                    item-key="product_id"
                    hide-default-footer
                    class="elevation-1"
                >
                  <template v-slot:item.product_id="{ item }">
                    {{ item.product_name  }}
                    
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" >
              <v-text-field
                  class=""
                  outlined
                  readonly
                  :value="item.customer.city"
                  label="شهر "
              ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="!isMobile">
                <v-text-field
                    outlined
                    class="mt-n8"
                    readonly
                    v-model="item.customer.address"
                    label="آدرس"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="isMobile">
                <v-textarea
                    class="mt-n8"
                    outlined
                    readonly
                    v-model="item.customer.address"
                    label="آدرس"
                ></v-textarea>
              </v-col>
              <v-col cols="12" >
              <v-text-field
                  class="mt-n8"
                  outlined
                  readonly
                  :value="item.customer.phone_number | MobileFilter"
                  label="شماره تلفن"
              ></v-text-field>
              </v-col>
              <v-col v-for="(x,index) in Object.keys(item.description_json)" :key="index" cols="12" class="mt-n8">
                <div v-if="item.description_json[x] !== ''">
                  <h4> {{descriptionState(x)}}</h4>
                  <v-textarea
                      outlined
                      readonly
                      v-model="item.description_json[x]"
                      label="توضیحات:"
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
        </td>
        </template>
        <template v-slot:footer >
            <v-row  class="justify-center " >
                <v-btn color="primary" :disabled="invioces.length < 98" @click="readDateWithOfSet()" class="ma-5">
                    موارد بیشتر
                </v-btn>
            </v-row>
        </template>
    </v-data-table>
    <v-snackbar
      v-model="active"
      multi-line
      :color="color"
      class="fonts"
      bottom
      right
    >
      {{ messages }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="active = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      class="fonts"
      v-model="modal"
      width="50vw"
    >
      <v-card>
        <v-card-title class=" fonts">
         تایید عملیات
        </v-card-title>
        <v-card-text class="fonts"> {{ modalMessage }} ؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="fonts"
            color="black"
            text
            @click="cancelModal"
          >
            بستن
          </v-btn>
          <v-btn
            class="fonts"
            :color="modalColor"
            @click="verifyModal"
          >
            تایید
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-bottom-sheet
        v-model="sheet"
        inset
      >
        <v-sheet
          class="text-center"
          height="40vh"
        >
          <v-row>
            <v-col cols="12">
              <p class="font-weight-bold"> تغییر وضعیت سفارش </p>
            </v-col>
            <v-col cols="12">
              <v-list shaped>
                <v-list-item-group
                  v-model="model"
                >
                  <template v-for="(item, i) in items">
                    <v-divider
                      v-if="!item"
                      :key="`divider-${i}`"
                    ></v-divider>

                    <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :value="item.value"
                      active-class="deep-purple--text text--accent-4"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="deep-purple accent-4"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mt-6"
                block
                color="primary"
                @click="changeStateInvoice()"
              >
                تایید
              </v-btn>
            </v-col>
            <v-col cols="6">
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mt-6"
                block
                color="error"
                @click="sheet = !sheet"
              >
                بستن
              </v-btn>
            </v-col>
            <v-col cols="1">
            </v-col>
          </v-row>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-flex>
</template>

<script>
import InvioceService from '../../services/invoce'
import product from '../../data/output'

  export default {
  components: {  },
    props: {
      title: {
        type: String,
        required: true
      },
      btnText1: {
        type: String,
        required: true
      },
      btnText2: {
        type: String,
        required: false
      },
      readState: {
        type: Number,
        required: true
      },
      sendState: {
        type: Number,
        required: true
      },
      rejectState: {
        type: Number,
        required: false
      },
      printed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data () {
      return {
        sheet: false,
        InputData: '',
        state: 0,
        invoicsState: [
          {
            text: 'همه',
            key: 0
          },
          {
            text: "در انتظار تایید مالی",
            key: 1
          },
          {
            text: " رد شده مالی",
            key: 2
          },
          {
            text: "در انتظار بسته بندی",
            key: 3
          },
          {
            text: "در انتظار ارسال",
            key: 4
          },
          {
            text: " ارسال شده",
            key: 5
          },
          {
            text: "تحویل مشتری",
            key: 6
          },
          {
            text: "نهایی",
            key: 7
          },
          {
            text: "رد نهایی",
            key: 8
          },
        ],
        dateStart: (new Date(Date.now() + (new Date()).getTimezoneOffset()* 40 * 60000)).toISOString().substr(0, 10),
        dateEnd: (new Date(Date.now() + (1000 * 60 * 60 * 24 * 1))).toISOString().substr(0, 10),
        menu: false,
        menu2: false,
        radioGroup: "1" ,
        modal: false,
        modalMessage: '',
        modalColor: 'primary',
        offset: 0,
        loadingTable: true,
        messages: '',
        active: false,
        color: 'primary',
        invioces: [],
        expanded: [],
        singleExpand: false,
        products: product,
        ItemHeaders: [
          { 
            text: 'محصول ', value: 'product_id' , align: 'start', sortable: false,
            // filter: value => {
            //  return (this.products.find(x => x.id === this.value)).name + value
            // }
          },
          { text: 'قیمت', value: 'price' },
          { text: ' مبلغ پرداخت شده', value: 'paid_price' },
          { text: ' تعداد', value: 'quantity' },
        ],
        dessertHeaders: [
          {
            value: 'index',
            text: '#',
          },
          {
            value: 'id',
            text: 'شماره سفارش',
          },
          {
            value: 'shygun_id',
            text: 'شماره شایگان',
          },
          { text: 'نام مشتری', value: 'customer.name' , align: 'start', sortable: false,},
          { text: 'چهار رقم آخر کارت', value: 'card_id' },
          { text: 'ساعت پرداخت', value: 'payment_date' },
          { text: 'مبلغ پرداخت شده', value: 'paid_price' },
          { text: ' تاریخ ایجاد', value: 'created_at' },
          { text: 'وضعیت', value: 'state' },
          { text: 'عملیات', value: 'user_id' , align: 'center'},
          { text: '', value: 'data-table-expand' },
        ],
        items: [
          ],
        allState: [
          {
            text: "در انتظار تایید مالی",
            value: 1
          },
          {
            text: " رد شده مالی",
            value: 2
          },
          {
            text: "تایید مالی - در انتظار بسته بندی",
            value: 3
          },
          {
            text: "تایید بسته بندی - در انتظار ارسال به پیک",
            value: 4
          },
          {
            text: " تایید ارسال به پیک -  در انتظار ارسال",
            value: 5
          },
          {
            text: "تایید تحویل به مشتری - ارسال شده",
            value: 6
          },
          {
            text: "نهایی",
            value: 7
          },
          {
            text: "رد نهایی",
            value: 8
          }
        ],
        model: [2],
        InovoiceId: null ,
      }
    },
    computed: {
      rule() {
        return this.$store.state.auth.user.user_role;
      },
      faMonthEnd() {
        return this.$options.filters.famonth(this.dateEnd);
      },
      faMonthStart() {
        return this.$options.filters.famonth(this.dateStart);
      }
    },
    mounted() {
      // setInterval(() => this.readData(), 120000);
    },
    created() {
        this.readData()
    },
    methods: {
      readData() {
        this.loadingTable = true
        InvioceService.Get(this.readState).then(
            data => {
                if(data.status === 200) {
                  this.invioces = data.data.invoices
                  this.loadingTable = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      searchInvoice(){
        this.offset = 0
        let x 
        switch (this.radioGroup) {
          case "1":
            x = '&customer_name=' + this.InputData
            InvioceService.ListInvoice(this.state,this.dateStart, this.dateEnd, x ,this.offset).then(
            data => {
                if(data.status === 200) {
                  this.invioces = data.data.invoices
                  this.loadingTable = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
            break;
          case "2":
            x = '&id=' + this.InputData
            InvioceService.ListInvoice(this.state,this.dateStart, this.dateEnd, x ,this.offset).then(
            data => {
                if(data.status === 200) {
                  this.invioces = data.data.invoices
                  this.loadingTable = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
            break;
          case "3":
            x = '&shygun_id=' + this.InputData
            InvioceService.ListInvoice(this.state,this.dateStart, this.dateEnd, x ,this.offset).then(
            data => {
                if(data.status === 200) {
                  this.invioces = data.data.invoices
                  this.loadingTable = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
            break;
          case "4":
            x = '&customer_phn=%2B98' + this.InputData.slice(1, this.InputData.length)
            InvioceService.ListInvoice(this.state,this.dateStart, this.dateEnd, x ,this.offset).then(
            data => {
                if(data.status === 200) {
                  this.invioces = data.data.invoices
                  this.loadingTable = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
            break;  
        }
      },
      stateShow(state){
        let stateMessage
        switch (state) {
          case 1:
            stateMessage = "در انتظار تایید مالی"
            this.stateColor = "warning"
            break;
          case 2:
            stateMessage = " رد شده مالی"
            this.stateColor = "error"
            break;
          case 3:
            stateMessage = "در انتظار بسته بندی"
            this.stateColor = "primary"
            break;
          case 4:
            stateMessage = "در انتظار ارسال"
            this.stateColor = "secondary"
            break;
          case 5:
            stateMessage = " ارسال شده"
            this.stateColor = "secondary"
            break;
          case 6:
            stateMessage = "تحویل مشتری"
            this.stateColor = "purple"
            break;
          case 7:
            stateMessage = "نهایی"
            this.stateColor = "deep-purple"
            break;
          case 8:
            stateMessage = "رد نهایی"
            this.stateColor = "deep-green"
            break;
          default:
            stateMessage = "تعریف نشده"
            this.stateColor = "light-blue"
        }
        return stateMessage
      },
      showColor(state){
        let stateColor
        switch (state) {
          case 1:
            stateColor = "warning"
            break;
          case 2:
            stateColor = "error"
            break;
          case 3:
            stateColor = "primary"
            break;
          case 4:
            stateColor = "secondary"
            break;
          case 5:
            stateColor = "secondary"
            break;
          case 6:
            stateColor = "purple"
            break;
          case 7:
            stateColor = "deep-purple"
            break;
          case 8:
            stateColor = "deep-red"
            break;
          default:
            stateColor = "light-blue"
        }
        return stateColor
      },
      handleExpansion(item, state) {
        const itemIndex = this.expanded.indexOf(item);

        state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
      },
      readDateWithOfSet(){
        this.offset = this.offset + 100
        this.loadingTable = true
        InvioceService.GetOffset(this.readState,this.offset).then(
            data => {
                if(data.status === 200) {
                  this.invioces = this.invioces.concat(data.data.invoices)
                  this.invioces = this.invioces.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.id === value.id
                    ))
                  )
                  this.loadingTable = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
      },
      checkAcceptAction(id){
        this.acceptID = id
        this.modalMessage = 'آیا از تایید شماره سفارش' + id + 'مطمئن هستید'
        this.modalColor = 'success'
        this.modal = true
      },
      checkRejecAction(id){
        this.rejectID = id
        this.modalMessage = 'آیا از رد شماره سفارش  ' + id + '  مطمئن هستید'
        this.modalColor = 'error'
        this.modal = true
      },
      verifyModal(){
        if(this.modalColor === 'error'){
          this.reject(this.rejectID)
          this.modal = false
        } else {
          this.accept(this.acceptID)
          this.modal = false
        }
      },
      cancelModal(){
        this.modal = false
        this.acceptID = 0
        this.rejectID = 0
        this.modalColor = 'primary'
      },
      changeState(item){
        this.InovoiceId = item.id
        this.items = []
        switch (item.state) {
          case 1:
            this.items.push(this.allState[0])
            this.items.push(this.allState[1])
            this.items.push(this.allState[2])
            this.model = this.items[0].value
            break;
          case 2:
            // this.items.push(this.allState[0])
            this.items.push(this.allState[1])
            // this.items.push(this.allState[2])
            this.model = this.items[0].value
            break;
          case 3:
            this.items.push(this.allState[0])
            this.items.push(this.allState[2])
            this.items.push(this.allState[3])
            this.model = this.items[1].value
            break;
          case 4:
            this.items.push(this.allState[2])
            this.items.push(this.allState[3])
            this.items.push(this.allState[4])
            this.model = this.items[1].value
            break;
          case 5:
            this.items.push(this.allState[3])
            this.items.push(this.allState[4])
            this.items.push(this.allState[5])
            this.model = this.items[1].value
            break;
          case 6:
            this.items.push(this.allState[4])
            this.items.push(this.allState[5])
            this.items.push(this.allState[6])
            this.items.push(this.allState[7])
            this.model = this.items[1].value
            break;
          case 7:
            this.items.push(this.allState[5])
            this.items.push(this.allState[6])
            this.items.push(this.allState[7])
            this.model = this.items[1].value
            break;
        } 
        this.sheet = !this.sheet
      },
      changeStateInvoice(){
        InvioceService.Update(this.InovoiceId,this.model,this.invioces.find(x => x.id === this.InovoiceId).description_json[this.readState]).then(
          data => {
              if(data.status === 200) {
                this.active = true
                this.messages = "با موفقیت ثبت شد"
                this.items = []
                this.readData()
                this.sheet = !this.sheet
              } else if (data.status === 401) {
                this.$router.push('/accessdenied');
              }
          },
          error => {
            if (error.status === 401) {
                this.$router.push('/accessdenied');
              }
              this.sheet = !this.sheet
          })
      },
      deleteInvoice(id){
          InvioceService.InvalidInvoice(id).then(
          data => {
              if(data.status === 200) {
                this.active = true
                this.messages = "با موفقیت غیر فعال شد"
                this.readData()
              } else if (data.status === 401) {
                this.$router.push('/accessdenied');
              }
          },
          error => {
            if (error.status === 401) {
                this.$router.push('/accessdenied');
              }
          })
      },
      descriptionState(id){
        let stateMessage
        switch (id) {
          case '0':
            stateMessage = "ادمین شبکه اجتماعی"
            break;
          case '1':
            stateMessage = "مدیر مالی"
            break;
          case '2':
            stateMessage = " مسئول بسته بندی  "
            break;
          case '3':
            stateMessage = " مسئول ارسال"
            break;
          case '4':
            stateMessage = "مسئول تایید"
            break;
          case '5':
            stateMessage = "مدیر"
            break;
          case '6':
            stateMessage = "تایید نهایی"
            break;
          default:
            stateMessage = "تعریف نشده"
        }
        return stateMessage
      },
    }

  }
</script>   

<style>

</style>