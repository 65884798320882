<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center v-if="access">
      <v-flex xs11 sm10 md10>
        <v-card class="elevation-12 fonts">
          <v-toolbar dark color="primary">
            <v-toolbar-title>گردش کار سفارش </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" text class="font-weight-bold" to="/orderstate">بازگشت</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-stepper
                v-model="e6"
                vertical
            >
                <span v-for="(invoice,index) in invioces" :key="index">
                    <v-stepper-step
                    :step="index + 1"
                    complete
                    :color="color(invoice.meta_key)"
                >
                <v-row>
                    <v-col cols="12">
                        <v-chip-group
                            column
                        >
                            <v-chip block label outlined color="primary" >{{ invoice.meta_value.split('_')[0]  }}</v-chip>
                            <v-chip label outlined color="blue-grey lighten-3"><div class="arrow-5"></div></v-chip> 
                            <v-chip block label outlined color="teal" >{{ invoice.meta_value.split('_')[1]  }}</v-chip>
                            <v-chip color="purple" label outlined >{{ invoice.username  }}</v-chip>
                            <v-chip label outlined color="orange">{{ invoice.created_at | famonthDay | fanum}}</v-chip> 
                        </v-chip-group>
                    </v-col>
                </v-row>
                </v-stepper-step>

                <v-stepper-content v-if="(index+1) !== invioces.length" :step="index + 1" style="border-right: 1px solid rgba(0, 0, 0, 0.12);">
               
                </v-stepper-content>
                </span>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import InvioceService from '../services/invoce'

export default {
  name: 'OrederStateDetails',
  components: {
  },
  data: () => ({
    access: false,
    invioces: [],
    e6: 1,
  }),
  computed: {
      loggedIn() {
          return this.$store.state.auth.fstatus.loggedIn;
        },
        rule() {
          return this.$store.state.auth.user.user_role;
        },
    },
    created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            this.access = true
            break;
          case 'SuperAdmin':
            this.access = true
            break;
          case 'FinancialManager':
            this.access = true
            break;
          case 'MonitoringOperator':
            this.access = true
            break;
          default:
            this.$router.push('/accessdenied');
        }
      }
    },
    mounted() {
        this.readDate()
    },
    methods: {
        readDate() {
            InvioceService.DetailsInvoice(this.$route.params.id).then(
            data => {
                if(data.status === 200) {
                  this.invioces = data.data.invoices
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            })
        },
        color(item) {
            switch (item) {
                case 'create_invoice':
                    return 'green'
                case 'undo_state':
                    return 'red'
                case 'change_state':
                    return 'blue'
             }
        }
    }

  }
</script>   
<style scoped>
.arrow-5 {
  transform: rotate(180deg);
  width:50px;
  height:30px;
  display: grid;
  overflow: hidden;
}
.arrow-5:before,
.arrow-5:after {
  content: "";
  grid-area: 1/1;
  background: currentColor;
  clip-path: polygon(0 10px,calc(100% - 15px) 10px,calc(100% - 15px) 0,100% 50%,calc(100% - 15px) 100%,calc(100% - 15px) calc(100% - 10px),0 calc(100% - 10px));
  animation: a5 1s infinite;
  transform: translate(calc(0% + var(--s,0%)));
}

.arrow-5:after {
  --s:-100%;
}

@keyframes a5 {
  80%,100%{transform: translate(calc(100% + var(--s,0%)))}
}
</style>