<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs11 sm8 md4>
        <v-card class="elevation-12 fonts">
          <v-toolbar dark color="primary">
            <v-toolbar-title>صفحه ورود</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field v-model="user.name" name="login" label="نام کاربری" type="text"></v-text-field>
              <v-text-field v-model="user.password" id="password"  name="password" label="رمز عبور" type="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="Login()" >ورود</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="active"
      multi-line
      :color="color"
      class="fonts"
      bottom
      right
    >
      {{ messages }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="active = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
    data: () => ({
      drawer: null,
      user: {
        name: null,
        password: null
      },
      messages: '',
      active: false,
      color: 'primary'
    }),
    props: {
      source: String
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.fstatus.loggedIn;
      },
      rule() {
        return this.$store.state.auth.user.user_role;
      }
    },
    created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            this.$router.push('/userlist');
            break;
          case 'SuperAdmin':
            this.$router.push('/userlist');
            break;
          case 'FinancialManager':
            this.$router.push('/financialcheck');
            break;
          case 'SocialOperator':
            this.$router.push('/InvoceAdd');
            break;
          case 'PackingOperator':
            this.$router.push('/packingcheck');
            break;
          case 'SendingOperator':
            this.$router.push('/sendingcheck');
            break;
          case 'DeliveryOperator':
            this.$router.push('/deliverycheck');
            break;
          case 'AccountingOperator':
            this.$router.push('/accountingcheck');
            break;
          case 'MonitoringOperator':
            this.$router.push('/orderstate');
            break;
          case 'StockClerk':
            this.$router.push('/sendingcheck');
            break;
          default:
            this.$router.push('/404');
        }
        // if(this.rule === '"SocialOperator"')
        //   this.$router.push('/InvoceAdd');
        // else
      }
    },
    methods: {
      Login() {
        if(this.user.name && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
                window.location.reload()
            },
            (errors) => {
              if(errors.response.status === 401){
                //this.loading = false;
                this.messages = "رمزعبور یا نام کاربری اشتباه می باشد"
                this.active = true
              }if(errors.response.status === 403){
                //this.loading = false;
                this.messages = "   کاربر غیر فعال می باشد"
                this.active = true
              } else{
               // this.loading = false;
                this.active = true
                this.message = "خطایی سمت سرور رخ داده لطفا دوره تلاش نمایید."
                // this.warn = true
              }
            }
          );
        } else {
          this.messages = "لطفا اطلاعات را کامل وارد نمایید."
          this.active = true
        }
      }
    }
}
</script>
