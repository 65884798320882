<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs11 sm10 md10>
        <v-card class="elevation-12 fonts">
          <v-toolbar dark color="primary">
            <v-toolbar-title>  لیست سفارش های ثبت نشده در شایگان    </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="loadingBtnAll" @click="sendJob(0)"> ارسال به صورت دسته ای </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="Jobs"
                        :items-per-page="10"
                        class="elevation-1"
                    >
                      <template v-slot:item.invoice.id="{ item }">
                          <!-- {{item.id}} -->
                          {{item.invoice.id | fanum}}
                      </template>
                      <template v-slot:item.invoice.created_at="{ item }">
                          <!-- {{item.id}} -->
                          {{item.invoice.created_at | famonthDay | fanum}}
                      </template>
                      <template v-slot:item.id="{ item }">
                          <!-- {{item.id}} -->
                          <v-btn  class="ma-2" color="success" :loading="item.id === loadingBtn" @click="sendJob(item.id)">  ارسال مجدد</v-btn>
                      </template>
                      <template v-slot:item.invoice.shygun_id>
                          <!-- {{item.id}} -->
                          <v-chip color="red" class="white--text" >ناموفق</v-chip>
                      </template>
                    </v-data-table>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="active"
      multi-line
      :color="color"
      class="fonts"
      bottom
      right
    >
      {{ messages }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="active = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import UserService from '../services/user'
export default {
  data: () => ({
    errorValidet: false,
    passwordDuplicated: null,
    password: null ,
    messageError: '',
    headers: [
        {
        text: 'شماره سفارش ',
        align: 'start',
        sortable: false,
        value: 'invoice.id',
        },
        { text: ' زمان ایجاد فاکتور', value: 'invoice.created_at' },
        { text: 'نام کاربر', value: 'invoice.customer.name' },
        
        { text: 'وضعیت', value: 'invoice.shygun_id' },
        { text: 'عملیات', value: 'id', align: 'center', },
    ],
    Jobs: [],
    messages: '',
    active: false,
    color: 'primary',
    customer: {
        name:'',
        phone_number: '',
        password:'',
        role: ''
    },
    userID: null,
    modalActivation: false,
    loadingBtn: -1,
    loadingBtnAll: false 
  }),
  created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            break;
          case 'SuperAdmin':
            break;
          case 'FinancialManager':
            break;
          default:
            this.$router.push('/accessdenied');
        }
      }
    },
  mounted() {
    this.readData()
  },
  computed: {
    loggedIn() {
        return this.$store.state.auth.fstatus.loggedIn;
    },
    rule() {
        return this.$store.state.auth.user.user_role;
    },
    list() {
        if (this.$store.state.auth.fstatus.loggedIn) {
        switch (this.$store.state.auth.user.user_role) {
          case 'OWNER':
            return this.OwnerList
          case 'SuperAdmin':
            return this.SuperAdminList
          case 'FinancialManager':
            return this.FinancialManagerList
        }
      }
      return 0
    }
  },
  methods: {
    readData() {
        UserService.ListJob().then(
            data => {
                if(data.status === 200) {
                  this.Jobs = data.data.jobs
                  
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            }) 
    },
    sendJob(id) {
      this.loadingBtnAll = true
      this.loadingBtn = id
      UserService.SendJob(id).then(
            data => {
                if(data.status === 200) {
                  this.active = true
                  this.messages = "با موفقیت ارسال شد"
                  this.readData()
                  this.loadingBtnAll = false
                  this.loadingBtn = -1
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                  this.loadingBtn = false
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                  this.loadingBtn = false
                }
            }) 
    },
  },
};
</script>
<style >
.v-application {
  font-family: Vazirmatn !important;
}
</style>