import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_BASE_URL   // process.env.VUE_APP_BASE_URL + '/api' //'http://138.201.77.42:8081/api'

class UserService {
//   getList() {
//     return axios.get(API_URL + 'backup/',);
//   }
//   deleteItem(id) {
//     return axios.delete(API_URL + 'backup/'+ id + "/",);
//   }
  Add(Data) {
    return axios.post(API_URL + 'auth/register',{username:Data.name, mobile_number: Data.phone_number, password : Data.password, role: Data.role},{ headers: authHeader() });
  }
  Get(){
    return axios.get(API_URL + 'auth/user?role=SocialOperator',{ headers: authHeader() });
  }
  List(){
    return axios.get(API_URL + 'auth/user',{ headers: authHeader() });
  }
  Performance(id){
    return axios.get(API_URL + 'sales/user_perf?id=' + id,{ headers: authHeader() });
  }
  Active(id,state){
    return axios.put(API_URL + 'auth/user?id=' + id,{employed : state},{ headers: authHeader() });
  }
  ChangePass(id,password){
    return axios.put(API_URL + 'auth/reset_password?id=' + id,{password : password},{ headers: authHeader() });
  }
  ResetPassword(id){
    return axios.put(API_URL + 'auth/reset_password?id=' + id,{},{ headers: authHeader() });
  }
  ListJob(){
    return axios.get(API_URL + 'sales/failed_jobs',{ headers: authHeader() });
  }
  SendJob(id){
    return axios.delete(API_URL + 'sales/failed_jobs?id=' + id ,{ headers: authHeader() });
  }
}
export default new UserService();