<template>
   <div>
    <v-btn block class="print_fonts ma-2 " @click="generateReport()" color="success">چاپ</v-btn>
     <vue-html2pdf
        class="custompdf print_fonts"
        :show-layout="false"
        :float-layout="true"
        :enable-download="isMobile"
        :preview-modal="!isMobile"
        :paginate-elements-by-height="1966"
        :filename="receiver"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a7"
        pdf-orientation="landscape"
        pdf-content-width="2790"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <!-- PDF Content Here -->
            <div class="border print_fonts rtlClass">
              <v-row class="mt5">
                <v-col cols="1">
                  
                </v-col>
                <v-col cols="8">
                  <p class="bold">فرستنده : میشا</p>
                  <br/>
                  <p class="f10 mtn4 bolds">آدرس : تهران-چیتگر-کوهک-مجتمع تجاری طوبی- طبقه ۱۳-پلاک ۳۱۳۴</p>
                </v-col>
                <v-col cols="3" class="mx0">
                  <v-img eager :src="image"></v-img>
                </v-col>
                <v-col cols="1">
                </v-col>
                <v-col cols="11">
                  <p class="f10 mtn24 bolds">تلفن: ۰۹۹۱۲۶۷۶۰۱۱ - ۰۲۱۴۶۰۸۲۷۸۵</p>
                </v-col>
              </v-row>
              <v-row  justify="center" align="center">
                <v-col>
                  <p class="f10 ltrtext mtn25">www.missha-ir.com</p>
                </v-col>
              </v-row>  
              <hr class="blackline"/>    
               <v-row class="mt2" >
                <v-col cols="1">

                </v-col>
                <v-col cols="5">
                  <p class="f9 bolds"><span class="bolds  f10">گیرنده:</span> {{ receiver | faSpaceNumber}}</p>
                </v-col>
                <v-col cols="5">
                  <p class="f9 bolds"><span class="bolds print_fonts f10">شماره تماس:</span> {{ mobile | MobileFilter }}</p>
                </v-col>
                <v-col cols="1">
                  
                </v-col>
                <v-col cols="1">

                </v-col>
                <v-col cols="11" class="mtn14">
                  <p class="f9 bolds"><span class="bolds f10">آدرس:</span> {{ address }}</p>
                </v-col>
              </v-row> 
            </div>
        </section>
    </vue-html2pdf>
   </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import qrcode from '../../assets/images/qrcode_misha.jpg'

export default {
  props: {
      receiver: {
        type: String,
        required: true
      },
      mobile: {
        type: String,
        required: true
      },
      address: {
        type: String,
        required: true
      },
  },
  data: () => ({
    image: qrcode,
    // receiver: 'ثمین آزمون تهرامی مقدم',
    // mobile: '۰۹۱۲۵۲۳۰۰۲۴',
    // address: 'تهران سعادت آباد خیابان علامه شمالی خیابان ۱۸ غربی پلاک ۳ واحد ۱۶'
  }),
  methods: {
      /*
          Generate Report using refs and calling the
          refs function generatePdf()
      */
      generateReport () {
          this.$refs.html2Pdf.generatePdf()
      }
  },

  components: {
      VueHtml2pdf
  }
}
</script>
<style scoped>
.border {
  margin-top: 0.07cm;
  margin-bottom: 0.01cm;
  margin-right: 0.5cm;
  margin-left: 0.3cm;
  height: 270px;
  border: 2px solid black;
  border-radius: 25px;
}
.rtlClass{
  text-align: right;
  direction: rtl;
}
.bold {
  font-weight: 800 !important;
  font-size: 15px;
}
.f10 {
  font-weight: 800;
  font-size: 12px;
}
.mtn4{
  margin-top: -12px;
}
.mx0{
  margin-right : -10px;
}
.mtn14{
  margin-top: -15px;
}
.mtn25{
  margin-top: -35px;
}
.ltrtext {
  direction: ltr;
  text-align: center;
  margin-top: -15px;
}
.blackline{
  color: black;
}
.bolds {
  font-weight: 800   ;
}
.mt5{
  margin-top: 5px;
}
.mt2{
  margin-top: 2px;
}
.f9 {
  font-size: 11px;
}
</style>