<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs11 sm10 md10>
        <v-card class="elevation-12 fonts">
          <v-toolbar dark color="primary">
            <v-toolbar-title> مدیریت کاربران    </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="success" to="/usercreate">ساخت کاربر جدید</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="Users"
                        :items-per-page="10"
                        class="elevation-1"
                    >
                      <template v-slot:item.mobile_number="{ item }">
                          <!-- {{item.id}} -->
                          {{ item.mobile_number | MobileFilter}}
                      </template>
                      <template v-slot:item.id="{ item }">
                          <!-- {{item.id}} -->
                          <v-btn v-if="(rule !== 'FinancialManager') && (item.employed)" class="ma-2" color="red" @click="statUser(item)"> غیر فعال کردن کاربر </v-btn>
                          <v-btn v-if="(rule !== 'FinancialManager') && (!item.employed)" class="ma-2" color="green" @click="statUser(item)">  فعال کردن کاربر </v-btn>
                          <v-btn  class="ma-2" color="primary" @click="changePassword(item.id)"> تغییر رمز </v-btn>
                          <!-- <v-btn  class="ma-2" color="warning" @click="resetPassword(item.id)"> بازنشانی رمز </v-btn> -->
                      </template>
                      <template v-slot:item.employed="{ item }">
                          <!-- {{item.id}} -->
                          <v-chip label outlined :color="item.employed ? 'green' : 'red'">{{item.employed ? 'فعال' : 'غیرفعال'}}</v-chip>
                      </template>
                    </v-data-table>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="active"
      multi-line
      :color="color"
      class="fonts"
      bottom
      right
    >
      {{ messages }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="active = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      class="fonts"
      v-model="modalActivation"
      width="50vw"
    >
      <v-card>
        <v-card-title class=" fonts">
          تغییر رمز
        </v-card-title>
        <v-card-text class="fonts"> 
          <v-text-field v-model="password" placeholder="Qwe.123" label="رمز جدید را وارد نمایید "></v-text-field>
          <v-text-field v-model="passwordDuplicated" placeholder="Qwe.123" label="رمز را دوباره تکرار نمایید"></v-text-field>
          <p v-if="errorValidet" class="red--text">{{messageError}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="fonts"
            color="black"
            text
            @click="modalActivation = false"
          >
            بستن
          </v-btn>
          <v-btn
            class="fonts"
            color="primary"
            @click="passwordUpdate()"
          >
            تایید
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import UserService from '../services/user'
export default {
  data: () => ({
    errorValidet: false,
    passwordDuplicated: null,
    password: null ,
    messageError: '',
    headers: [
        {
        text: 'نام کاربر',
        align: 'start',
        sortable: false,
        value: 'username',
        },
        { text: 'شماره موبایل', value: 'mobile_number' },
        { text: 'نقش کاربر', value: 'role' },
        { text: 'عملیات', value: 'id', align: 'center', },
        { text: 'وضعیت', value: 'employed' },
    ],
    Users: [],
    messages: '',
    active: false,
    color: 'primary',
    customer: {
        name:'',
        phone_number: '',
        password:'',
        role: ''
    },
    userID: null,
    modalActivation: false
  }),
  created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            break;
          case 'SuperAdmin':
            break;
          case 'FinancialManager':
            break;
          default:
            this.$router.push('/accessdenied');
        }
      }
    },
  mounted() {
    this.readData()
  },
  computed: {
    loggedIn() {
        return this.$store.state.auth.fstatus.loggedIn;
    },
    rule() {
        return this.$store.state.auth.user.user_role;
    },
    list() {
        if (this.$store.state.auth.fstatus.loggedIn) {
        switch (this.$store.state.auth.user.user_role) {
          case 'OWNER':
            return this.OwnerList
          case 'SuperAdmin':
            return this.SuperAdminList
          case 'FinancialManager':
            return this.FinancialManagerList
        }
      }
      return 0
    }
  },
  methods: {
    readData() {
        UserService.List().then(
            data => {
                if(data.status === 200) {
                  this.Users = data.data.users
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            }) 
    },
    statUser(item) {
      UserService.Active(item.id,!item.employed).then(
            data => {
                if(data.status === 200) {
                  this.active = true
                  this.messages = "با موفقیت انجام شد"
                  this.readData()
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            }) 
    },
    changePassword(id) {
      this.userID = id
      this.modalActivation = true
    },
    passwordUpdate(){
      if(!this.password || this.password.length < 8) {
        this.messageError = "لطفا پسوردی مطابق الگو انتخاب نمایید."
        this.errorValidet = true
      } else if(!this.passwordDuplicated || this.passwordDuplicated !== this.password) {
        this.messageError = "عدم تطابق دو پسورد لطفا دوباره بررسی نمایید."
        this.errorValidet = true
      } else {
        UserService.ChangePass(this.userID, this.password).then(
            data => {
                if(data.status === 200) {
                  this.active = true
                  this.messages = "با موفقیت انجام شد"
                  this.readData()
                  this.errorValidet = false
                  this.modalActivation = false
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            }) 
      }
    },
    resetPassword(id){
      UserService.ResetPassword(id).then(
            data => {
                if(data.status === 200) {
                  this.active = true
                  this.messages = "با موفقیت انجام شد"
                  this.readData()
                } else if (data.status === 401) {
                  this.$router.push('/accessdenied');
                }
            },
            error => {
              if (error.status === 401) {
                  this.$router.push('/accessdenied');
                }
            }) 
    }
  },
};
</script>
<style >
.v-application {
  font-family: Vazirmatn !important;
}
</style>