export default {
    Owner: [
        "SuperAdmin"
        ,
        "FinancialManager"
        ,
        "SocialOperator"
        ,
        "PackingOperator"
        ,
        "SendingOperator"
        ,
        "DeliveryOperator"
        ,
        "AccountingOperator"
        ,
        "MonitoringOperator"
        ,
        "StockClerk"

    ],
    SuperAdmin: [
        "FinancialManager"
        ,
        "SocialOperator"
        ,
        "PackingOperator"
        ,
        "SendingOperator"
        ,
        "DeliveryOperator"
        ,
        "AccountingOperator"
        ,
        "MonitoringOperator"
        ,
        "StockClerk"
    ],
    FinancialManager: [
        "SocialOperator"
        ,
        "PackingOperator"
        ,
        "SendingOperator"
        ,
        "DeliveryOperator"
        ,
        "AccountingOperator"
        ,
        "MonitoringOperator"
        ,
        "StockClerk"
    ],
};