<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center v-if="access">
      <monitoring :btnText1="dataInput.text1" :title="dataInput.title" printed :readState="dataInput.readstate" :sendState="dataInput.sendstate"></monitoring>
    </v-layout>
  </v-container>
</template>
<script>
import monitoring from '../components/Page/monitoring.vue'

export default {
  name: 'AccountingCheck',
  components: {
    monitoring,
  },
  data: () => ({
    access: false,
    dataInput: {
      title: '  سفارش ها ',
      text1: ' تایید بسته بندی ',
      text2: '',
      readstate: 0,
      sendstate: 4,
      rejectstate: 0,
    }
  }),
  computed: {
      loggedIn() {
          return this.$store.state.auth.fstatus.loggedIn;
        },
        rule() {
          return this.$store.state.auth.user.user_role;
        },
    },
    created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            this.access = true
            break;
          case 'SuperAdmin':
            this.access = true
            break;
          case 'FinancialManager':
            this.access = true
            break;
          case 'MonitoringOperator':
            this.access = true
            break;
          case 'SocialOperator':
            this.access = true
            break;
          default:
            this.$router.push('/accessdenied');
        }
      }
    },
    methods: {
    }

  }
</script>   